.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    outline: none;
    border: 1px solid #fcb800;
}

@media (hover: hover) and (pointer: fine) {
    .image-gallery-thumbnail:hover {
		outline: none;
		border: 2px solid #ff3300;
    }
}