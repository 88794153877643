.accordion {
    border: none;
}

.accordion__button:hover {
    background-color: #fcb800;
}

.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 13px;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
    padding-top: 5px;
}