.pagination li > a {
    padding: 0 14px;
    position: relative;
    display: inline-block;
    z-index: 30;
    color: #999;
    line-height: 32px;
    border: none;
    background-color: transparent;
    color: #000;
    vertical-align: middle;
    font-size: 14px;
}

.pagination {
    cursor: pointer;
}

li.pagination_active > a {
	background-color: #fcb800;
}