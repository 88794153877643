.ps-cart__items {
    max-height: 300px;
    overflow-y: scroll;
}

.cart__footer {
    padding: 10px 20px 20px;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-top: 0;
}

