.ps-product--detail .ps-product__meta p:after {
 width: 0px; 
}

@media (max-width: 479px) {
    .ps-product--detail .ps-product__shopping .ps-btn {
        display: block;
        width: 100%;
        margin-right: 0;
    }
}