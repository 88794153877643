    .crop {
        width: 100px;
        height: 100px;
        overflow: hidden;
    }

    .crop img {
        width: 100px;
        height: 100px;
        margin: -5px 0 0 -5px;
    }