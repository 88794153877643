.range-slider .range-slider__thumb {
    position: absolute;
    z-index: 3;
    top: 50%;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #fcb800;
}


.range-slider .range-slider__range {
    position: absolute;
    z-index: 1;
    transform: translate(0, -50%);
    top: 50%;
    width: 100%;
    height: 97%;
    background: #fcb800;
}

.range-slider {
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;
    height: 4px;
    background: #ddd;
    border-radius: 4px;
}

	