
@media (min-width: 768px) {
    .ps-block--store-banner .ps-block__user .ps-block__user-content {
        padding-left: 30px;
        padding-bottom: 0px;
    }
}
.ps-block--store-banner .ps-block__user .ps-block__user-avatar img {
    /* width: 150px; */
    /* height: 150px; */
    margin-bottom: 10px;
    border-radius: 50%;
}

.ps-block--store-banner .ps-block__user .ps-block__user-avatar img {
    width: inherit;
    height: inherit;
    margin-bottom: 10px;
    border-radius: 50%;
}